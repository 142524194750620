<template>
  <div class="flex items-center gap-2">
    <div v-if="showImages" class="flex flex-wrap gap-2">
      <UiLazyImg
        v-for="item in data.materials"
        :key="item.id"
        class="size-4 rounded-full overflow-hidden"
        :src="
          getImageByPosition(item.medias, 'list')?.file?.url ||
          '/images/no-image.webp'
        "
        :alt="
          getImageByPosition(item.medias, 'list')?.file?.alt ||
          item.currentTranslation?.title
        "
      />
    </div>
    <span v-if="showCurrentMaterial">{{
      currentMaterial?.currentTranslation?.title
    }}</span>
  </div>
</template>

<script setup lang="ts">
import { CatalogData, Material, MaterialCategory } from "ecom-types";
import { CardProduct } from "~/composables";

interface ProductMaterialsWithCategories extends MaterialCategory {
  materials: Material[];
}

const props = withDefaults(
  defineProps<{
    data: ProductMaterialsWithCategories;
    product?: CardProduct | CatalogData | null;
    showImages?: boolean;
    showCurrentMaterial?: boolean;
  }>(),
  { showImages: false, product: null, showCurrentMaterial: false },
);

// const plan = ref(props.data?.materials?.[0]?.sku);

const currentMaterial = computed<Material | undefined>(() => {
  return props.data.materials?.find((el) =>
    // props.product?.default_config?.materials?.includes(el.id)
    props.product?.default_config?.materials?.some((i) => i === el.id),
  );
});
</script>
